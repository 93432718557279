import React from 'react'

export const  TechnologypageConfig= {
  
settings: {
    layout: {
        config: {
            navbar: {
                display: false
            },
            toolbar: {
                display: false
            },
            footer: {
                display: true
            },
            leftSidePanel: {
                display: false
            },
            rightSidePanel: {
                display: false
            }
        }
    }
    
},
    routes  : [
        {
            path: '/pages/technology',
            component: React.lazy(() => import('./Technologypage'))
        }
    ]


};

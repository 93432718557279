import React from 'react'

export const ViewmemberprofileConfig= {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
               
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
        
    },
    routes  : [
        {
            path     : '/pages/ViewMemberProfile',
            component: React.lazy(() => import('./Viewmemberprofile'))
        }
    ]
}

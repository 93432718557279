import React from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const LogoutDialog = ({ isOpen, onClose, onConfirm }) => {
  const history = useHistory();

  const handleLogoutConfirm = () => {
    // Close the dialog
    onClose();

    // Clear the access token from sessionStorage
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('tokenExpiry');

    // Redirect to the sign-in page
    history.push('/pages/AdminSignInPage');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          backgroundColor: 'white', // White background
          color: 'black', // Black text color
          padding: '20px', // Padding inside the dialog
          borderRadius: '10px', // Optional for rounded corners
        },
      }}
    >
      <DialogTitle style={{ color: 'black' }}>Logout Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: 'black', backgroundColor: 'white' }}>
          Cancel
        </Button>
        <Button onClick={handleLogoutConfirm} style={{ color: 'black', backgroundColor: 'white' }}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;

import React from 'react'

export const ViewprofileConfig= {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
              
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
        
    },
    routes  : [
        {
            path     : '/pages/ViewProfile',
            component: React.lazy(() => import('./Viewprofile'))
        }
    ]
}

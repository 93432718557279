import React from 'react'

export const TermsConditionsConfig={
  settings: {
    layout: {
        config: {
            navbar: {
                display: false
            },
            toolbar: {
                display: false
            },
            footer: {
                display: true
            },
            leftSidePanel: {
                display: false
            },
            rightSidePanel: {
                display: false
            }
        }
    }
    
},
    routes  : [
        {
            path: '/pages/TermsConditions',
            component: React.lazy(() => import('./TermsConditions1'))
        }
    ]


};
  

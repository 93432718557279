import React from 'react'

export const HeartbeatgraphConfig= {
 
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
       
    },
    routes  : [
        {
            path     : '/pages/HeartBeatGraph',
            component: React.lazy(() => import('./Heartbeatgraph'))
        }
    ]
};




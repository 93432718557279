import React from 'react';
export const HeartContentConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                // leftSidePanel: {
                //     display: false
                // },
                // rightSidePanel: {
                //     display: false
                // }
            }
        }
    },
    routes  : [
        {
            path     : '/pages/HeartContent',
            component: React.lazy(() => import('./HeartContentPage'))
        }
    ]
};
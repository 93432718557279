import { constant } from 'lodash'
import React from 'react'

export const SuperAdminSignInConfig={


    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: true
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
        
    },
    routes  : [
        {
            path     : '/pages/SuperAdminSignIn',
            component: React.lazy(() => import('./SuperAdminSignIn'))
        }
    ]
};




 

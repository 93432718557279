import React from 'react';

export const companyprofilepageConfig= {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: true
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
        
    },
    routes  : [
        {
            path     : '/pages/Companyprofilepage',
            component: React.lazy(() => import('./companyprofilepage'))
        }
    ]
};

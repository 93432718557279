import { constant } from 'lodash';
import React from 'react'

export const SignInPageConfig ={

        settings: {
            layout: {
                config: {
                    navbar: {
                        display: false
                    },
                    toolbar: {
                        display: false
                    },
                    footer: {
                        display: true
                    },
                    leftSidePanel: {
                        display: false
                    },
                    rightSidePanel: {
                        display: false
                    }
                }
            }
            
        },
        routes  : [
            {
                path     : '/pages/SignInPage',
                component: React.lazy(() => import('./SignInPage1'))
            }
        ]
    };
    
   


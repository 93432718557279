import React from 'react'

export const AddnewdeviceConfig = {

    settings: {
        layout: {
            config: {
                navbar: {
                    display: true
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: true
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
        
    },
    routes  : [
        {
            path     : '/pages/AddNewDevice',
            component: React.lazy(() => import('./Addnewdevice'))
        }
    ]

}

import { ForgotPasswordPageConfig } from './auth/forgot-password/ForgotPasswordPageConfig';
import { RegisterPageConfig } from './auth/register/RegisterPageConfig';
import { ResetPasswordPageConfig } from './auth/reset-password/ResetPasswordPageConfig';
import { LoginPageConfig } from './auth/login/LoginPageConfig';
import { MailConfirmPageConfig } from './auth/mail-confirm/MailConfirmPageConfig';
import { LockPageConfig } from './auth/lock/LockPageConfig';
import { Login2PageConfig } from './auth/login-2/Login2PageConfig';
import { Register2PageConfig } from './auth/register-2/Register2PageConfig';
import { ForgotPassword2PageConfig } from './auth/forgot-password-2/ForgotPassword2PageConfig';
import { ResetPassword2PageConfig } from './auth/reset-password-2/ResetPassword2PageConfig';
import { ComingSoonPageConfig } from './coming-soon/ComingSoonPageConfig';
import { Error404PageConfig } from './errors/404/Error404PageConfig';
import { Error500PageConfig } from './errors/500/Error500PageConfig';
import { MaintenancePageConfig } from './maintenance/MaintenancePageConfig';
import { ModernInvoicePageConfig } from './invoices/modern/ModernInvoicePageConfig';
import { CompactInvoicePageConfig } from './invoices/compact/CompactInvoicePageConfig';
import { PricingStyle1PageConfig } from './pricing/style-1/PricingStyle1PageConfig';
import { PricingStyle2PageConfig } from './pricing/style-2/PricingStyle2PageConfig';
import { PricingStyle3PageConfig } from './pricing/style-3/PricingStyle3PageConfig';
import { ProfilePageConfig } from './profile/ProfilePageConfig';
import { ClassicSearchPageConfig } from './search/classic/ClassicSearchPageConfig';
import { ModernSearchPageConfig } from './search/modern/ModernSearchPageConfig';
import { FaqPageConfig } from './faq/FaqPageConfig';
import { KnowledgeBasePageConfig } from './knowledge-base/KnowledgeBaseConfig';
import { DesktopOnePageConfig } from './desktopone/DesktopOnePageConfig';
import { signindesktopConfig } from './SignIn/signindesktopConfig';
import { DesktopTwoPageConfig } from './desktoptwo/DesktopTwoPageConfig';
import { DesktopConfig } from './desktopPages/DesktopConfig';
import { DesktopfiveConfig } from './desktopfive/DesktopfiveConfig';
import { DesktopsixConfig } from './desktopsix/DesktopsixConfig';
import { CSSGridConfig } from './profilepages/CSSGridConfig';
import { signUpdesktopConfig } from './SignUp/signUpdesktopConfig';
import { ProfilePageNewConfig } from './profilepagenew/ProfilePageNewConfig';
import { DesktopsevenConfig } from './desktopseven/DesktopsevenConfig';
import { DesktopsevenpageConfig } from './desktopseven/DesktopsevenpageConfig';
import { CompanyPageConfig } from './company/CompanyPageConfig';
import { TechnologypageConfig } from './technology/TechnologypageConfig';
import { ContactpageConfig } from './contact/ContactpageConfig';
import { ProductConfig } from './product/ProductConfig';

import { HomePageConfig } from './Home/HomePageConfig';
import { SignInPageConfig } from './SignInPage/SignInPageConfig';
import { SignUpPageConfig } from './SignupPage/SignupPageConfig';

import { createcompanyConfig} from './CreateCompany/createcompanyConfig';
import {createdepartmentConfig} from './CreateDepartment/createdepartmentConfig';
import {addcustomersConfig} from './AddCustomers/addcustomersConfig';
import {chartpageConfig} from './ChartPage/chartpageConfig';
import {adminsigninConfig} from './AdminSignIn/adminsigninConfig';
import { adminsignInConfig } from './AdminSignInPage/adminsignInConfig';
import { privacyConfig } from './Privacy/privacyConfig';
import { TermsConditionsConfig } from './TermsCondtions/TermsConditionsConfig';
import { companylistsConfig } from './CompanyLists/companylistsConfig';
import { departmentslistsConfig } from './DepartmentLists/departmentslistsConfig';
// import { dashboardConfig } from './Dashboard/dashboardConfig';
import { FaqsPageConfig } from './Faqs/FaqsPageConfig';
import { blogConfig } from './Blog/BlogConfig';
import { HeartContentConfig } from './HeartContent/HeartContentConfig';
import { bloodPressureConfig } from './BloodPressure/bloodPressureConfig';
import { LungsContentConfig } from './LungsContent/LungsContentConfig';
import { superadminConfig } from './SuperAdmin/superadminConfig';
import { patientdetailsConfig } from './Patientdetails/patientdetailsConfig';
import { patientformConfig } from './PatientForm/PatientformConfig';
import { adddeviceConfig } from './Adddevice/adddeviceConfig';
import { beddetailsConfig } from './Beddetails/beddetailsConfig';
import { SigninwithphoneConfig } from './Signinwithphone/signinwithphoneConfig';

import { companyprofileConfig } from './Companyprofile/companyprofileConfig';
import { addbedConfig } from './Addbed/addbedConfig';
import { bedanddeviceConfig } from './AllocatedBedandDevice/bedanddeviceConfig';
import { SignInConfig } from './DepartmentSignIn/SignInConfig';
import { analyticsConfig } from './Analytics/analyticsConfig';
import { departmentprofileConfig } from './DepartmentProfile/departmentprofileConfig';
import { devicedetailsConfig } from './DeviceDetails/devicedetailsConfig';
import { PrintReportConfig } from './Printreport/PrintReportConfig';
import { signoutConfig } from './Signout/signoutConfig';
import { SuperAdminSignInConfig } from './SuperAdminSignIn/SuperAdminSigInConfig';
import { companyprofilepageConfig } from './Companyprofilepage/companyprofilepageConfig';
import { imagestempConfig} from './Individualimages/imagestempConfig';

import { gurageConfig } from './Gurage/gurageConfig';
import {newsConfig} from './News/newsConfig'
// import { newdashboardConfig } from './NewDashboard/newdashboardConfig';
import { dashboardConfig } from './Dashboard/dashboardConfig';
import { doctorregistrationConfig } from './DoctorRegistration/doctorregistrationConfig';

import { doctordetailsConfig } from './DoctorDetails/doctordetailsConfig';
import { AddnewdeviceConfig } from './AddNewDevice/AddnewdeviceConfig';
import {futurepageConfig} from './FuturePage/futurepageConfig';
import {createprofileConfig} from './CreateProfile/createprofileConfig'
import {AddmemberConfig} from './AddMember/AddmemberConfig'
import {ViewprofileConfig} from './ViewProfile/ViewprofileConfig'
import {ViewmemberprofileConfig} from './ViewMemberProfile/ViewmemberprofileConfig'
import {IndividualreportConfig} from './IndividualReport/IndividualreportConfig'
import {TimedomainConfig} from './TimeDomain/TimedomainConfig'
import {FrequencydomainConfig} from './FrequencyDomain/FrequencydomainConfig'
import {NonLineardomainConfig} from './NonLinearDomain/NonLineardomainConfig'
import {HeartbeatgraphConfig} from './HeartBeatGraph/HeartbeatgraphConfig'
import { ImpiloVistaBrouchurepdfConfig } from './ImpiloVista_Brochure.pdf/ImpiloVistaBrouchurepdfConfig';









export const pagesConfigs = [
    LoginPageConfig,
    RegisterPageConfig,
    ResetPasswordPageConfig,
    ForgotPasswordPageConfig,
    MailConfirmPageConfig,
    LockPageConfig,
    Login2PageConfig,
    Register2PageConfig,
    ForgotPassword2PageConfig,
    ResetPassword2PageConfig,
    ComingSoonPageConfig,
    Error404PageConfig,
    Error500PageConfig,
    MaintenancePageConfig,
    ModernInvoicePageConfig,
    CompactInvoicePageConfig,
    PricingStyle1PageConfig,
    PricingStyle2PageConfig,
    PricingStyle3PageConfig,
    ProfilePageConfig,
    ClassicSearchPageConfig,
    ModernSearchPageConfig,
    FaqPageConfig,
    KnowledgeBasePageConfig,
    DesktopOnePageConfig,
    signindesktopConfig,
    DesktopTwoPageConfig,
    DesktopConfig,
    DesktopfiveConfig,
    signUpdesktopConfig,

    DesktopsixConfig,
    CSSGridConfig,
    ProfilePageNewConfig,
    DesktopsevenConfig,
    DesktopsevenpageConfig,
    CompanyPageConfig,
    TechnologypageConfig,
    ContactpageConfig,
    ProductConfig,
   
    HomePageConfig,
    SignInPageConfig,
    SignUpPageConfig,
  
    createcompanyConfig,
    createdepartmentConfig,
    addcustomersConfig,
    chartpageConfig,
    adminsigninConfig,
    adminsignInConfig,
    privacyConfig,
    TermsConditionsConfig,
    companylistsConfig,
    departmentslistsConfig,
  
    FaqsPageConfig,
    blogConfig,
    HeartContentConfig ,
    bloodPressureConfig,
    LungsContentConfig,
    superadminConfig,
  
    patientformConfig,
     adddeviceConfig,
     beddetailsConfig,
  patientdetailsConfig,
  SigninwithphoneConfig,
  companyprofileConfig,
  addbedConfig,
bedanddeviceConfig,
SignInConfig,
analyticsConfig,
departmentprofileConfig,
devicedetailsConfig,
PrintReportConfig,
signoutConfig,
SuperAdminSignInConfig,
companyprofilepageConfig,
imagestempConfig,
// newdashboardConfig,
gurageConfig,
newsConfig,

dashboardConfig,
doctorregistrationConfig,
doctordetailsConfig,
AddnewdeviceConfig,
futurepageConfig,
createprofileConfig,
AddmemberConfig,
ViewprofileConfig,
ViewmemberprofileConfig,
IndividualreportConfig,
TimedomainConfig,
FrequencydomainConfig,
NonLineardomainConfig,
HeartbeatgraphConfig,
ImpiloVistaBrouchurepdfConfig


];
